/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/aos@2.3.4/dist/aos.min.js
 * - /npm/sweetalert@2.1.2/dist/sweetalert.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
